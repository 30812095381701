import classNames from 'classnames/bind';
import React from 'react';
import PropType from 'prop-types';

import Layout from 'components/Layout';

import styles from '../legal/legal.module.scss';

const cx = classNames.bind(styles);

const PrivacyPolicyPage = ({ pageContext }) => (
  <Layout
    isGAR
    lang={pageContext.lang}
    t={pageContext.layout}
    urls={pageContext.urls}
  >
    <div className={cx('container')}>
      <h1 className={cx('title')}>{pageContext.content.title}</h1>
      <div
        className={cx('content')}
        dangerouslySetInnerHTML={{ __html: pageContext.content.content }}
      />
    </div>
  </Layout>
);

PrivacyPolicyPage.propTypes = {
  pageContext: PropType.object.isRequired,
};

export default PrivacyPolicyPage;
